// main: ../style.scss
/********************************
*********************************
Chats
*********************************
*********************************/
.chatbox {
  color: #444;
  small {
    color: #444;
  }

  > [class*="col"] {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.chatbox-body {
  flex: 1;
}

.chatbox-header {
  @extend %border-bottom;
  height: rem(110);
  display: flex;
  align-items: center;
}

.chatbox-user-list {
  .user-name {
    line-height: 1;
  }
  small {
    font-size: em(10);
  }
  .icon-box header a {
    border: 1px solid rgba($content-color, .2);
  }
  .user-list-single {
    padding: 0 ($spacer * 1);
    position: relative;
    margin-bottom: ($spacer * 1.5);
  }
}

.chatbox-messages {
  padding: $spacer;
  .message {
    margin-bottom: $spacer;
    flex-flow: row-reverse;
    align-items: flex-end;
    figure {
      margin-left: $spacer;
    }
    .media-body {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    p {
      max-width: 50%;
      margin-bottom: .5em;
      padding: .5em 1em;
      background: $color-scheme;
      color: #fff;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      position: relative;
      &:first-child::after,
      &:first-child::before {
        content: '';
        @include position(absolute, 50% -7px null null);
        @include size(0);
        display: block;
        border-style: solid;
        border-width: 5px 0 5px 7px;
        border-color: transparent transparent transparent $color-scheme;
        transform: translateY(-50%);
      }
    }
    &.reply {
      flex-flow: row;
      align-items: flex-start;
      .media-body {
        align-items: flex-start;
      }
      figure {
        margin-left: auto;
        margin-right: $spacer;
      }
      p {
        color: $content-color;
        background: #fff;
        border: 1px solid $theme-border-color;
        border-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        &:first-child::before {
          @include position(null, null auto null -8px);
          border-width: 5px 7px 5px 0;
          border-color: transparent $theme-border-color transparent transparent;
        }
        &:first-child:after {
          @include position(null, null auto null -7px);
          border-width: 5px 7px 5px 0;
          border-color: transparent #fff transparent transparent;
        }
      }
    }
  }
}
