// main: ../style.scss

/* Media Elements
========================*/
.mejs__container {
  max-width: 100%;
  width: 100% !important;
  font-family: $font-family-base;
  background: none;
}

.mejs__overlay {
  width: 100% !important;
}

.mejs__mediaelement * {
  width: 100% !important;
  max-width: 100%;
}

.mejs__overlay-button {
  background: none;
  @include size(rem(60));
  &:before {
    content: '\e99e';
    font-family: 'feather';
    font-size: rem(60);
    line-height: rem(60);
  }
}

.video {
  max-width: 100%;
}

.no-control-onstart.mejs__video--playing {
  .mejs__controls {
    display: block;
  }
}

.no-controls-onstart:not(.mejs__video--playing) {
  .mejs__controls {
    display: none;
  }
}
